import React, { useState, useEffect } from "react";
import "./ExperiencePage.css";
import { Table, Tag, Input, Space } from "antd";
import { ExperienceItemModel } from "../../models/ExperienceItemModel";
import Highlighter from "react-highlight-words";

function ExperiencePage() {
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<ExperienceItemModel[] | undefined>(
    undefined
  );
  const [filteredListData, setFilteredListData] = useState(listData);

  const handleResetButton = (): void => {
    setSearchText("");
    setFilteredListData(listData);
  };

  const columns = [
    {
      title: "Organization",
      dataIndex: "Organization",
      key: "Organization",
      width: 700,
      sorter: {
        compare: (a:any, b:any) => (''+a.Organization).localeCompare(b.Organization),
        multiple: 3,
      },
      render: (organization: string) => (
        <>
          {
            <Highlighter
              highlightClassName="search-highlighted"
              searchWords={[searchText]}
              autoEscape={true}
              textToHighlight={organization}
            />
          }
        </>
      ),
    },
    {
      title: "Industry",
      dataIndex: "Industry",
      key: "Industry",
      width: 100,
      sorter: {
        compare: (a:any, b:any) => (''+a.Industry).localeCompare(b.Industry),
        multiple: 2,
      },
      render: (industry: string) => (
        <>
          {
            <Highlighter
              highlightClassName="search-highlighted"
              searchWords={[searchText]}
              autoEscape={true}
              textToHighlight={industry}
            />
          }
        </>
      ),
    },
    {
      title: "Standards",
      key: "Standards",
      dataIndex: "Standards",
      width: 600,
      render: (standards: string[]) => (
        <>
          {standards.map((standard) => {
            return standard.trim().length > 0 ? (
              <Tag
                color={"blue"}
                key={standard}
                className="standard-tag"
                onClick={(e: any) => {
                  handleCustomSearch(standard);
                }}
              >
                <Highlighter
                  highlightClassName="search-highlighted"
                  searchWords={[searchText]}
                  autoEscape={true}
                  textToHighlight={standard.toUpperCase()}
                />
              </Tag>
            ) : null;
          })}
        </>
      ),
    },
    {
      title: "Country",
      dataIndex: "Country",
      key: "Country",
      width: 50,
      sorter: {
        compare: (a:any, b:any) => (''+a.Country).localeCompare(b.Country),
        multiple: 1,
      },
      render: (country: string) => (
        <>
          {
            <Highlighter
              highlightClassName="search-highlighted"
              searchWords={[searchText]}
              autoEscape={true}
              textToHighlight={country}
            />
          }
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchFile();
  }, []);

  const fetchFile = () => {
    let clientsJson;
    fetch("/data/clients.json")
      .then((res) => res.json())
      .then((data) => {
        clientsJson = data;
        clientsJson.forEach((element: any) => {
          element.Standards = element.Standards.split(",");         
        });
        setListData(clientsJson);
        setFilteredListData(clientsJson);
      });
  };

  const handleCustomSearch = (searchKey: string) => {
    setSearchText(searchKey);
    if (!searchKey || searchKey.length <= 0) {
      handleResetButton();
    }
    const filteredOganizations = listData
      ? listData.filter((listItem: ExperienceItemModel) =>
          listItem.Organization.toLowerCase().includes(searchKey.toLowerCase())
        )
      : [];
    const filteredCountries = listData
      ? listData.filter((listItem: ExperienceItemModel) =>
          listItem.Country.toLowerCase().includes(searchKey.toLowerCase())
        )
      : [];
    const filteredStandards = listData
      ? listData.filter(
          (listItem: ExperienceItemModel) =>
            listItem.Standards.filter((standardItem: string) =>
              standardItem.toLowerCase().includes(searchKey.toLowerCase())
            ).length > 0
        )
      : [];
    const jointArray = [
      ...filteredOganizations,
      ...filteredCountries,
      ...filteredStandards,
    ];
    const uniqueArray = jointArray.filter(
      (item, index) => jointArray.indexOf(item) === index
    );
    uniqueArray.sort((item1, item2) => item1.key - item2.key);
    setFilteredListData(uniqueArray);
  };

  return (
    <div className="experience">
      <div className="container">
        <Space style={{ marginBottom: 16 }}>
          <Input
            placeholder={`Search`}
            value={searchText}
            allowClear
            onChange={(e: any) => {
              handleCustomSearch(e.target.value);
            }}
          />
          {/* <Button onClick={handleResetButton}>Clear Search</Button> */}
        </Space>
        <Table columns={columns} dataSource={filteredListData} />
      </div>
    </div>
  );
}

export default ExperiencePage;
