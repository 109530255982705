import React, { useState } from "react";
import "./FlipCardComponent.css";
import ReactCardFlip from "react-card-flip";

const FlipCardComponent = (props: any) => {
  const [isFlip, setFlipped] = useState(false);

  const cardFlip = (e: any) => {
    e.preventDefault();
    setFlipped(!isFlip);
  };

  return (
    <div className="flip-card" onMouseEnter={cardFlip} onMouseLeave={cardFlip}>
      <ReactCardFlip isFlipped={isFlip} flipDirection="horizontal">
        <div className="flip-card-front">
          <p>
            <span className={props.icon} />
            <br />
            {props.front}
          </p>
        </div>
        <div className="flip-card-back">
          <p>{props.back}</p>
        </div>
      </ReactCardFlip>
    </div>
  );
};

export default FlipCardComponent;
