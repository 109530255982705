import React from "react";
import "./topmenu.component.css";
import { NavLink } from "react-router-dom";

function TopMenu() {
  
  return (
        <div>
          <NavLink to="/" exact className="staticbtn">Home</NavLink>
          <div className="dropdown">
            <NavLink to="/about" onClick={e => e.preventDefault()}  className="staticbtn">About us</NavLink>
            <div className="dropdown-content">
              <NavLink activeClassName="drop-active" to="/about/vision" >Vision & Mission</NavLink>
              <NavLink activeClassName="drop-active" to="/about/staff" >Staff & Company activity</NavLink>
              <NavLink activeClassName="drop-active" to="/about/training">Training</NavLink>
              <NavLink activeClassName="drop-active" to="/about/experience">Experience</NavLink>
            </div>
          </div>
          <NavLink to="/media" className="staticbtn">Media</NavLink>
          <NavLink to="/links" className="staticbtn">Links</NavLink>
          {/* <div className="dropdown">
            <NavLink to="/e-services"  onClick={e => e.preventDefault()} className="staticbtn">E-Services</NavLink>
            <div className="dropdown-content">
                <NavLink activeClassName="drop-active" to="/e-services/srequest" >Service request</NavLink>
                <NavLink activeClassName="drop-active" to="/e-services/portal" >AEC Portal</NavLink>
            </div>
          </div> */}
          <NavLink to="/contact" className="staticbtn">Contact us</NavLink>
        </div>
  );
}

export default TopMenu;
