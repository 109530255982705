import React from "react";
import "./StatCountersComponent.css";
import CountUp from "react-countup";

const StatCountersComponent = () => {
  return (
    <div className="container flex-row counters">
      <div className="container flex-row">
        <div className="counter">
          <CountUp end={21} duration={3} />
          <div className="counter-title">Years</div>
        </div>
        <div className="counter">
          <CountUp end={187} duration={3} />
          <div className="counter-title">Projects</div>
        </div>
        <div className="counter">
          <CountUp end={2} duration={3} />
          <div className="counter-title">Countries</div>
        </div>
      </div>
    </div>
  );
};

export default StatCountersComponent;
    