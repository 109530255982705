import React from "react";
import "./ServicesCardsComponent.css";
import FlipCardComponent from "../flip-card/FlipCardComponent";

const ServicesCardsComponent = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="ser-snippet-title">
          <h1>Our Services</h1>
        </div>
        <div className="flex-row">
          <FlipCardComponent
            front="Qualification"
            icon="fa fa-briefcase"
            back="We provide Qualification and Consultations services for a wide set of ISO standards"
          />
          <FlipCardComponent
            front="Audit & Inspection"
            icon="fa fa-list"
            back="We provide audit and inspection for different organizations"
          />
          <FlipCardComponent
            front="Training"
            icon="fa fa-graduation-cap"
            back="We provide training in different areas which include quality management trainings, technical trainings and HSE trainings"
          />
          <FlipCardComponent
            front="Project Management"
            icon="fa fa-calendar"
            back="We provide outsourced Project management services for a wide set of sectors"
          />
          <FlipCardComponent
            front="Software"
            icon="fa fa-code"
            back="We provide efficient and cost friendly customized software solutions, digitizing any needed process"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesCardsComponent;
