import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { ToastProvider } from "react-toast-notifications";
import { BackTop } from "antd";
import HeaderComponent from "../header/HeaderComponent";
import HomePage from "../../pages/home/HomePage";
import MediaPage from "../../pages/media/MediaPage";
import ContactUsPage from "../../pages/contact/ContactUsPage";
import FooterComponent from "../footer/FooterComponent";
import VisionAndMissionPage from "../../pages/vision-and-mission/VisionAndMissionPage";
import LinksPage from "../../pages/links/LinksPage";
import ExperiencePage from "../../pages/experience/ExperiencePage";
import TrainingPage from "../../pages/training/TrainingPage";
import StaffAndActivityPage from "../../pages/staff-and-activity/StaffAndActivityPage";
import ServiceRequestPage from "../../pages/service-request/ServiceRequestPage";

import "font-awesome/css/font-awesome.min.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ArticleComponent from "../article/ArticleComponent";

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <HeaderComponent />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about/vision" component={VisionAndMissionPage} />
          <Route path="/about/staff" component={StaffAndActivityPage} />
          <Route path="/about/training" component={TrainingPage} />
          <Route path="/about/experience" component={ExperiencePage} />
          <Route path="/media" component={MediaPage} />
          <Route path="/links" component={LinksPage} />
          <Route path="/contact" component={ContactUsPage} />
          <Route path="/e-services/srequest" component={ServiceRequestPage} />
          <Route path="/e-services/portal" component={HomePage} />
          <Route path="/article/:id" component={ArticleComponent} />
          <Redirect from="*" to="/" />
        </Switch>
        <FooterComponent />
        <BackTop />
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
