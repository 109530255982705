import React, { useEffect, useState } from "react";
import "./ArticleComponent.css";

const ArticleComponent = (props: any) => {
  const [article, setArticle] = useState<any>({});
  useEffect(() => {
    let articlesJson;
    let article;
    fetch("/data/articles.json")
      .then((res) => res.json())
      .then((data) => {
        articlesJson = data;
        console.log(articlesJson);
        article = articlesJson.filter(
          (item: any) => item.articleId === props.match.params.id
        )[0];
        console.log(article);
        setArticle(article);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="article flex-row">
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: article.fullText }}></div>
      </div>
    </div>
  );
};

export default ArticleComponent;
