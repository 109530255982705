import React from "react";
import "./StaffAndActivityPage.css";

function StaffAndActivityPage() {
  return (
    <div className="staff">
      <div className="container">
        <div className="section">
          <h2>Company's staff</h2>
          <p></p>
          <ul>
            <li>
              AEC's GM Dr.Abdou Saad El-Din Moustafa is an Egyptian Engineering
              Syndicate Telecommunication &amp; Quality Management System
              Certified Consultant Engineer ,IRCA Certified Lead Auditor, M.sc.
              Degree in System and Computers Engineering, Al Azhar
              University,Faculty Of Engineering and phd degree in Business
              Administration, Washington University USA , Exclusive
              representative of Washington International University
            </li>
            <li>
              AEC's Employees are a handpicked collection of experts &amp;
              Engineers (combinations of phd specialized in different fields
              &amp; IRCA Certified Auditors and Lead auditors as well as a team
              of Egyptian Engineering Syndicate certified consultants.
            </li>
          </ul>
          <p></p>
        </div>
        <div className="section no-border">
          <h2>Company's activity</h2>
          <p></p>
          <ul>
            <li >
              Qualify the organizations management system according to the
              international standards (ISO 9001/2008 -ISO14001/2004 ? ISO
              13485/2003 -BSOHSAS18001/2007- ISO17799 -ISO/IEC17025/2005, HACCP,
              ISO 18189/2007, ISO 26000, ISO22000, and ISO/IEC17011) and others.
            </li>
            <li>
              <span >
                {" "}
                Provide consultations for CE /UL/Kite marks.
              </span>
            </li>
            <li>
              <span >
                Provide training (management ? technical).
              </span>
            </li>
            <li>
              <span >
                Design software packages upon the customer request.
              </span>
            </li>
            <li>
              <span >
                Conducting environmental feasibility study.
              </span>
            </li>
            <li>
              <span >Managing Projects.</span>
            </li>
            <li>
              <span >Auditing &amp; inspection.</span>
            </li>
          </ul>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default StaffAndActivityPage;
