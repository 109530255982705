import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";
import ArticlePreviewComponent from "../../components/article/preview/ArticlePreviewComponent";
import StatCountersComponent from "../../components/stat-counters/StatCountersComponent";
import ServicesCardsComponent from "../../components/services-cards/ServicesCardsComponent";

const HomePage = () => {

  return (
    <div id="home">
      <div className="container flex-row">
        <div id="welcome" className="container">
          <div className="title">
            <h2>General Manager's word</h2>
          </div>
          <p className="gm-word">
            The Arab Co. for engineering and systems consultations (AEC) has
            been established in 1999, our objective is to introduce high level
            of service to our customers to fulfill and achieve customer
            requirement and satisfaction. We are an organization that aims to be
            the first consultancy firm in Egypt, Arab countries and in the
            Middle East...
            <Link to={'/article/1'}>More</Link>
          </p>
        </div>
        <div id="banner" className="container">
          <img src="/images/QUALITY.jpg" width="400" height="400" alt="" />
        </div>
      </div>
      <StatCountersComponent />
      <div id="three-column" className="container flex-row">
        <div className="aec-role">
          <h1 className="aec-role-title">AEC's role towards the society</h1>
          <p className="aec-role-paragraph">
            Arab Company for Engineering & Systems Consultations was established
            in the 13th of june 1999, since then it has been on the continous
            move towards improvement of its quality of service and products. AEC
            beleives of having a role in the development of its society and
            environment as well as the development of its society's culture and
            its mindset. AEC also beleives in the importance of integrating new
            technologies with the already known work processes, which aids in
            achieving the targeted results effeciently. Bearing all of this in
            mind, AEC always make sure of playing its roles effectively and
            effeciently by co-operating with other organizations or NGOs.
          </p>
        </div>
        {/* <div className="imp-readings flex-column">
          <Article
            image="/images/covid19.jpg"
            title="COVID-19: Workplace challenges"
            titleLink="https://www.iso.org/news/ref2504.html"
            paragraph="Keeping safe and healthy in the workplace is important at any time, but even more so in times like these. "
          />
          <Article
            image="/images/medical.png"
            title="Medical devices get quality treatment"
            titleLink="https://www.iso.org/news/2016/02/Ref2046.html"
            paragraph="Quality and safety are non-negotiable in the medical devices industry. "
          />
        </div> */}
      </div>
      <ServicesCardsComponent/>
      <div id="three-column" className="container flex-row">
        <div>
          <span className="arrow-down"></span>
        </div>
        <ArticlePreviewComponent
          image="/images/whatisQuality.png"
          stretchImage="true"
          title="What is Quality Management?"
          summary="Quality management is the act of overseeing all activities and tasks needed to maintain a desired level of excellence."
          button="Continue Reading"
          articleId={2}
        />
        <ArticlePreviewComponent
          image="/images/history.png"
          stretchImage="true"
          title="History of Quality"
          summary="The quality movement can be traced back to the late 13th century, where craftsmen began organizing into unions called guilds."
          button="Continue Reading"
          articleId={3}
        />
        <ArticlePreviewComponent
          image="/images/checklist.png"
          stretchImage="true"
          title="Quality Concepts"
          summary="Quality management include many important concepts, some of them are : Continuous Improvement and Cost of Quality (COQ) ..."
          button="Continue Reading"
          articleId={4}
        />
      </div>
    </div>
  );
};

export default HomePage;
