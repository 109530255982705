import { EmailRequestModel } from "../models/EmailRequestModel";
import axios, { AxiosRequestConfig } from "axios";

export const sendEmail = async (emailRequest: EmailRequestModel) => {
  console.log("sending email to:", emailRequest);
  const url = process.env.REACT_APP_BACKEND_URL;
  // console.log('url iss',url);
  const options: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: emailRequest,
  };
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
